#manage-subscriptions.ui.container {
  width: 90vw !important;
}

#manage-subscriptions.ui.container .ui.tabular.menu .active.item {
  margin-bottom: -2px;
}

.channel-options {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-right: -1rem;
}

/* options */
.channel-options-channel {
  display: flex;
  align-items: flex-end;
}

.inclined {
  height: 10rem;
  width: 3.5rem;
  /* same in .breaking-header .channels div */
  white-space: nowrap;
}

.inclined div {
  transform:
    rotate(315deg);
  transform-origin: top left;
}

.formControl {
  display: flex;
  justify-content: flex-end;
}

/* subscriptions */
.block.search .listingsubscriptions .listing-item,
.block.search .listingsubscriber .listing-item {
  padding: 0.3em 0.4em 0.2em 0.4em;
}

.block.search .listingsubscriptions .listing-item:nth-child(2n),
.block.search .listingsubscriber .listing-item:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.04);
}

.block.search .listingsubscriptions .listing-item .listing-title .breaking-header h4 a.expired {
  color: lightgray;
}

/* subscriber */
input[name^="field-subscriber_"] {
  margin-right: 1rem;
}

.breaking-header .channels {
  display: flex;
  flex-direction: row
}

.breaking-header .channels .distributionmode {
  width: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 0.5rem;
  padding: 0.3rem 0.1rem;
  background-color: rgba(0, 0, 50, 0.06);
}

.listingsubscriptions .breaking-header .channels .distributionmode {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.checkbox.read-only input.hidden+label {
  cursor: not-allowed;
}

.breaking-header .channels .distributionmode .ui.checkbox {
  margin: auto;
}

.breaking-header .channels .distributionmode .ui.checkbox.immediate {
  grid-column: 1;
}

.breaking-header .channels .distributionmode .ui.checkbox.digest {
  grid-column: 2;
}

/* edit subscription */
.channel-row {
  margin-bottom: .5rem;
  font-size: 75%;
}