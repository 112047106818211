/* sticky columns */
@media only screen and (min-width: 900px) {
  .zui-wrapper {
    position: relative;
  }
  .zui-scroller {
    padding: 0 1px;
    border-right: 1px solid #b8c6c8;
    border-left: 1px solid #b8c6c8;
    margin-right: 120px;
    margin-left: 380px;
    overflow-x: auto;
    overflow-y: visible;
    border: none !important;
  }
  .ui.table.zui-table {
    border: none !important;
  }
  .ui.table.zui-table th {
    height: 3.3rem;
  }
  .ui.table.zui-table th:not(.zui-sticky-col):not(.zui-sticky-col-right),
  .ui.table.zui-table td:not(.zui-sticky-col):not(.zui-sticky-col-right) {
    min-width: 9rem;
  }
  .ui.table.zui-table th.zui-sticky-col,
  .ui.table.zui-table td.zui-sticky-col {
    position: absolute;
    left: 0;
    width: 40px;
    -webkit-hyphens: auto;
            hyphens: auto;
    white-space: normal;
  }
  .ui.table.zui-table th.zui-sticky-col:nth-of-type(2),
  .ui.table.zui-table td.zui-sticky-col:nth-of-type(2) {
    left: 40px;
  }
  .ui.table.zui-table th.zui-sticky-col:nth-of-type(3),
  .ui.table.zui-table td.zui-sticky-col:nth-of-type(3) {
    left: 80px;
    width: 300px;
  }
  .ui.table.zui-table th.zui-sticky-col:nth-of-type(3) div,
  .ui.table.zui-table td.zui-sticky-col:nth-of-type(3) div {
    overflow: hidden;
  }
  .ui.table.zui-table th.zui-sticky-col:nth-of-type(3) div .contentsitem_title,
  .ui.table.zui-table td.zui-sticky-col:nth-of-type(3) div .contentsitem_title {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ui.table.zui-table th.zui-sticky-col-right,
  .ui.table.zui-table td.zui-sticky-col-right {
    position: absolute;
    right: 0;
    width: 120px;
  }
  .ui.table.zui-table th.zui-sticky-col-right .actions,
  .ui.table.zui-table td.zui-sticky-col-right .actions {
    display: flex;
    justify-content: flex-end;
  }
  .ui.table.zui-table th.zui-sticky-col-right .actions svg,
  .ui.table.zui-table td.zui-sticky-col-right .actions svg {
    padding: 0;
  }
  .ui.table.zui-table th .review_state,
  .ui.table.zui-table td .review_state {
    display: flex;
    justify-content: space-between;
  }
}
