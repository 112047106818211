.ui.form.formplus {
  border: 3px solid darkgrey;
  padding-bottom: 1rem;
}
.ui.form.formplus .ui.raised.segments,
.ui.form.formplus .ui.raised.segment {
  box-shadow: none;
  border: none;
}
.ui.form.formplus .ui.secondary.attached.segment {
  padding: 1.5rem 1rem;
  font-size: 1.5rem;
}
.ui.form.formplus .formtabs.menu a.item {
  text-transform: uppercase;
  color: teal;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ui.form.formplus .fields.error .field textarea,
.ui.form.formplus .field.error .field textarea,
.ui.form.formplus .fields.error .field select,
.ui.form.formplus .field.error .field select,
.ui.form.formplus .fields.error .field input:not([type]),
.ui.form.formplus .field.error .field input:not([type]),
.ui.form.formplus .fields.error .field input[type="date"],
.ui.form.formplus .field.error .field input[type="date"],
.ui.form.formplus .fields.error .field input[type="datetime-local"],
.ui.form.formplus .field.error .field input[type="datetime-local"],
.ui.form.formplus .fields.error .field input[type="email"],
.ui.form.formplus .field.error .field input[type="email"],
.ui.form.formplus .fields.error .field input[type="number"],
.ui.form.formplus .field.error .field input[type="number"],
.ui.form.formplus .fields.error .field input[type="password"],
.ui.form.formplus .field.error .field input[type="password"],
.ui.form.formplus .fields.error .field input[type="search"],
.ui.form.formplus .field.error .field input[type="search"],
.ui.form.formplus .fields.error .field input[type="tel"],
.ui.form.formplus .field.error .field input[type="tel"],
.ui.form.formplus .fields.error .field input[type="time"],
.ui.form.formplus .field.error .field input[type="time"],
.ui.form.formplus .fields.error .field input[type="text"],
.ui.form.formplus .field.error .field input[type="text"],
.ui.form.formplus .fields.error .field input[type="file"],
.ui.form.formplus .field.error .field input[type="file"],
.ui.form.formplus .fields.error .field input[type="url"],
.ui.form.formplus .field.error .field input[type="url"],
.ui.form.formplus .fields.error textarea,
.ui.form.formplus .field.error textarea,
.ui.form.formplus .fields.error select,
.ui.form.formplus .field.error select,
.ui.form.formplus .fields.error input:not([type]),
.ui.form.formplus .field.error input:not([type]),
.ui.form.formplus .fields.error input[type="date"],
.ui.form.formplus .field.error input[type="date"],
.ui.form.formplus .fields.error input[type="datetime-local"],
.ui.form.formplus .field.error input[type="datetime-local"],
.ui.form.formplus .fields.error input[type="email"],
.ui.form.formplus .field.error input[type="email"],
.ui.form.formplus .fields.error input[type="number"],
.ui.form.formplus .field.error input[type="number"],
.ui.form.formplus .fields.error input[type="password"],
.ui.form.formplus .field.error input[type="password"],
.ui.form.formplus .fields.error input[type="search"],
.ui.form.formplus .field.error input[type="search"],
.ui.form.formplus .fields.error input[type="tel"],
.ui.form.formplus .field.error input[type="tel"],
.ui.form.formplus .fields.error input[type="time"],
.ui.form.formplus .field.error input[type="time"],
.ui.form.formplus .fields.error input[type="text"],
.ui.form.formplus .field.error input[type="text"],
.ui.form.formplus .fields.error input[type="file"],
.ui.form.formplus .field.error input[type="file"],
.ui.form.formplus .fields.error input[type="url"],
.ui.form.formplus .field.error input[type="url"] {
  background: transparent;
}
.ui.form.formplus .fields.error textarea:focus,
.ui.form.formplus .field.error textarea:focus,
.ui.form.formplus .fields.error select:focus,
.ui.form.formplus .field.error select:focus,
.ui.form.formplus .fields.error input:not([type]):focus,
.ui.form.formplus .field.error input:not([type]):focus,
.ui.form.formplus .fields.error input[type="date"]:focus,
.ui.form.formplus .field.error input[type="date"]:focus,
.ui.form.formplus .fields.error input[type="datetime-local"]:focus,
.ui.form.formplus .field.error input[type="datetime-local"]:focus,
.ui.form.formplus .fields.error input[type="email"]:focus,
.ui.form.formplus .field.error input[type="email"]:focus,
.ui.form.formplus .fields.error input[type="number"]:focus,
.ui.form.formplus .field.error input[type="number"]:focus,
.ui.form.formplus .fields.error input[type="password"]:focus,
.ui.form.formplus .field.error input[type="password"]:focus,
.ui.form.formplus .fields.error input[type="search"]:focus,
.ui.form.formplus .field.error input[type="search"]:focus,
.ui.form.formplus .fields.error input[type="tel"]:focus,
.ui.form.formplus .field.error input[type="tel"]:focus,
.ui.form.formplus .fields.error input[type="time"]:focus,
.ui.form.formplus .field.error input[type="time"]:focus,
.ui.form.formplus .fields.error input[type="text"]:focus,
.ui.form.formplus .field.error input[type="text"]:focus,
.ui.form.formplus .fields.error input[type="file"]:focus,
.ui.form.formplus .field.error input[type="file"]:focus,
.ui.form.formplus .fields.error input[type="url"]:focus,
.ui.form.formplus .field.error input[type="url"]:focus {
  background: transparent;
}
.ui.form.formplus .error.inline.required.field input,
.ui.form.formplus .inline.field.password input,
.ui.form.formplus .inline.field.confirm_password input {
  border-color: #E0B4B4;
}
.ui.form.formplus .error.inline.required.field .ui.label,
.ui.form.formplus .inline.field.password .ui.label,
.ui.form.formplus .inline.field.confirm_password .ui.label {
  display: none;
}
.ui.form.formplus .inline.field .wrapper {
  min-height: 30px;
  border: none;
}
.Toastify__toast-body svg.icon {
  display: none;
}
