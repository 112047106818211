/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/open-sans-v34-latin-regular.ec2df544.eot);
  /* IE9 Compat Modes */
  src: url(/static/media/open-sans-v34-latin-regular.ec2df544.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */
    url(/static/media/open-sans-v34-latin-regular.e43b5358.woff2) format('woff2'),
    /* Super Modern Browsers */
    url(/static/media/open-sans-v34-latin-regular.600270a4.woff) format('woff'),
    /* Modern Browsers */
    url(/static/media/open-sans-v34-latin-regular.4e6feb3d.ttf) format('truetype'),
    /* Safari, Android, iOS */
    url(/static/media/open-sans-v34-latin-regular.ef2d6483.svg#OpenSans) format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/open-sans-v34-latin-italic.5337f0ca.eot);
  /* IE9 Compat Modes */
  src: url(/static/media/open-sans-v34-latin-italic.5337f0ca.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */
    url(/static/media/open-sans-v34-latin-italic.3d5107ab.woff2) format('woff2'),
    /* Super Modern Browsers */
    url(/static/media/open-sans-v34-latin-italic.f1e23d36.woff) format('woff'),
    /* Modern Browsers */
    url(/static/media/open-sans-v34-latin-italic.2f30eb04.ttf) format('truetype'),
    /* Safari, Android, iOS */
    url(/static/media/open-sans-v34-latin-italic.85120c16.svg#OpenSans) format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/open-sans-v34-latin-700.8f015edd.eot);
  /* IE9 Compat Modes */
  src: url(/static/media/open-sans-v34-latin-700.8f015edd.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */
    url(/static/media/open-sans-v34-latin-700.e45478d4.woff2) format('woff2'),
    /* Super Modern Browsers */
    url(/static/media/open-sans-v34-latin-700.dce81ef0.woff) format('woff'),
    /* Modern Browsers */
    url(/static/media/open-sans-v34-latin-700.b1633c6b.ttf) format('truetype'),
    /* Safari, Android, iOS */
    url(/static/media/open-sans-v34-latin-700.983dfda9.svg#OpenSans) format('svg');
  /* Legacy iOS */
}

/* ruda-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ruda';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/ruda-v23-latin-regular.a34871bd.eot);
  /* IE9 Compat Modes */
  src: url(/static/media/ruda-v23-latin-regular.a34871bd.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */
    url(/static/media/ruda-v23-latin-regular.23ac1f13.woff2) format('woff2'),
    /* Super Modern Browsers */
    url(/static/media/ruda-v23-latin-regular.4cfd4f9b.woff) format('woff'),
    /* Modern Browsers */
    url(/static/media/ruda-v23-latin-regular.8fcc4f75.ttf) format('truetype'),
    /* Safari, Android, iOS */
    url(/static/media/ruda-v23-latin-regular.585823ee.svg#Ruda) format('svg');
  /* Legacy iOS */
}

/* ruda-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ruda';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/ruda-v23-latin-500.556bf335.eot);
  /* IE9 Compat Modes */
  src: url(/static/media/ruda-v23-latin-500.556bf335.eot?#iefix) format('embedded-opentype'),
    /* IE6-IE8 */
    url(/static/media/ruda-v23-latin-500.94ae1277.woff2) format('woff2'),
    /* Super Modern Browsers */
    url(/static/media/ruda-v23-latin-500.92db7e6e.woff) format('woff'),
    /* Modern Browsers */
    url(/static/media/ruda-v23-latin-500.e12258c7.ttf) format('truetype'),
    /* Safari, Android, iOS */
    url(/static/media/ruda-v23-latin-500.8f7264a8.svg#Ruda) format('svg');
  /* Legacy iOS */
}