/* 
 * @rohberg/volto-searchkit-block
 * springisnow-volto-searchkit-block.less
 */
/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element specify theme name below */
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/* Extras */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Component's site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Site theme site.variables */
/*******************************
     User Global Variables
*******************************/
/* Colors */
.workflowColors {
  color: #963c38;
}
.workflowColors.internally_published {
  color: #963c38;
}
.workflowColors.private {
  color: #ed4033;
}
.workflowColors.disabled {
  color: #ed4033;
}
.workflowColors.published {
  color: #963c38;
}
.workflowColors.enabled {
  color: #963c38;
}
.workflowColors.intranet {
  color: #51aa55;
}
.workflowColors.draft {
  color: #f6a808;
}
.workflowColors.internal {
  color: #f68b08;
}
/*-------------------
    documentationsidebar
--------------------*/
/*-------------------
    Breakpoints
--------------------*/
/*-------------------
 Component Variables
---------------------*/
/* Default */
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
.fnresults .ui.items > .item.private > .content > a.header {
  color: red;
}
.fnresults .ui.items > .item button,
.fnresults .ui.items > .item .ui.button {
  padding: 0;
  border: none;
  margin: 0.25rem 0.1rem 0.25rem 0em;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-weight: bold;
}
.fnresults .ui.items > .item button:hover,
.fnresults .ui.items > .item .ui.button:hover {
  background-color: transparent;
}
.fnresults .ui.items > .item .extra {
  color: #6e6a6a;
}
.fnresults .ui.items > .item .extra .label {
  margin-right: 0.5em;
}
.searchbar-wrapper {
  display: flex;
  align-items: stretch;
}
.searchbar-wrapper .ui.fluid.action.input {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .searchbar-wrapper .ui.fluid.action.input {
    width: 100%;
  }
}
.searchbar-wrapper .ui.fluid.action.input > input {
  width: unset !important;
  flex: 1 1;
  border-color: rgba(0, 0, 0, 0.13) !important;
  border-radius: 0;
}
.searchbar-wrapper .ui.fluid.action.input > input:focus {
  border-color: #85b7d9 !important;
}
.searchbar-wrapper .button {
  border-radius: 0;
}
.searchbar-wrapper .ui.action.input > button.ui.button {
  display: none;
}
.searchbar-wrapper .icon.delete {
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: #878686;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}
.searchbar-wrapper .icon.delete.unselected {
  opacity: 0;
}
.facetedsearch_filter {
  opacity: 1;
  transition: opacity 1s, visibility 1s;
  visibility: visible;
}
.facetedsearch_filter.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
}
.facetedsearch_filter.cards .ui.card {
  margin: 1em 0.5em 1em 0em;
}
.facetedsearch_filter.dropdown .bucketaggregations {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 767px) {
  .facetedsearch_filter.dropdown .bucketaggregations {
    grid-template-columns: 1fr;
  }
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter {
  display: flex;
  min-height: 3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem 0.4rem 1rem;
  margin: 0;
  background-color: #edf1f2;
  font-family: 'Poppins';
  font-size: 1rem;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter > .dropdown.icon {
  font-size: 1rem;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter.selected {
  background-color: #c4d5da;
  color: #6e6a6a;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter.selected .text {
  max-width: 95%;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter .menu {
  width: 100%;
  border: none;
  background-color: #edf1f2;
  box-shadow: none;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter .menu > .item {
  padding: 0.4rem 1.4rem 0.4rem 1rem !important;
  line-height: 1.4em;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter .menu > .item .item.isSelected {
  font-weight: bold;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter .menu > .item .item.isSelected svg {
  stroke: currentColor;
  stroke-width: 3px;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter div.visible .menu {
  display: block !important;
  visibility: visible !important;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .ui.dropdown.fnfilter.scrolloptions .menu > .item {
  padding-right: 0.9rem !important;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .deleteFilter {
  display: flex;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: #878686;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}
.facetedsearch_filter.dropdown .bucketaggregations .bucketAE .deleteFilter.unselected {
  opacity: 0;
}
body.section_without_filter .facetedsearch_filter {
  opacity: 0;
  visibility: hidden;
}
.searchsections {
  display: flex;
  gap: 20px;
}
.searchsections button {
  padding: 0.7rem 1rem 0.7rem 1rem;
  border: none;
  background-color: #f3f1f1;
  color: #6e6a6a;
  cursor: pointer;
}
.searchsections button.active {
  background-color: #dfd8d8;
}
@media only screen and (max-width: 767px) {
  .searchsections {
    flex-direction: column;
    gap: 0.5rem;
  }
  .searchsections button {
    display: inline-block;
  }
}
.countlabel {
  font-weight: bold;
}
.sortby {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.sortby .ui.button {
  padding: 0;
  background-color: transparent;
  color: rgba(70, 92, 98, 0.77);
  font-weight: normal;
}
.sortby .ui.button.button-active {
  color: #6e6a6a;
  font-weight: bold;
}
.sortby .ui.button:hover {
  background-color: transparent;
  color: #6e6a6a;
}
.ui.placeholder.segment .search.icon {
  display: none;
}
.highlight {
  margin-top: 0.5rem;
  cursor: zoom-in;
}
.highlight div {
  margin-bottom: 0.5rem;
}
.highlight em {
  background-color: #e6f2ce;
}
body.section-test-searchkit-querystrings .ui.segment.top-wrapper {
  display: none;
}
