.slate-editor-link {
  color: #963c38;
}
button.hash-link {
  padding: 0;
  border: none;
  background: transparent;
  color: #963c38;
  cursor: pointer;
  text-decoration: none;
}
button.hash-link:hover {
  color: #963c38;
  text-decoration: underline;
}
