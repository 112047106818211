.glossarytooltip {
  cursor: help;
  border-bottom: 2px dotted #963c38;
}
.ui.popup {
  -webkit-hyphens: auto;
          hyphens: auto;
  font-size: 75% !important;
}
.ui.popup ol {
  padding-left: 1rem;
}
.hidden-helper {
  display: none;
}
.glossary h2.letter {
  background: #963c38;
  color: white;
  margin: 2rem 0 1rem;
  padding: 0.3em 0.5em;
}
.term h3 span {
  font-size: 80%;
}
