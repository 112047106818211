.ui.dropdown.bookmarkmenu .header {
    padding: .7rem 1rem !important;
    font-size: 1rem !important;
    color: unset;
}

.ui.dropdown.bookmarkmenu>.menu,
.ui.dropdown.upward.bookmarkmenu>.menu {
    width: 40rem;
    max-width: 80vw !important;
    /* hack unwanted "upward" dropdown */
    top: 100%;
    bottom: auto;
}

/* TODO restrict to .dropdown.bookmarkmenu */
.ui.dropdown>.left.menu .menu.scrolling {
    margin: 0 !important;
}

ul.volto-bookmarks-list {
    padding: 0 1rem;
    /* width: 40rem !important; */
    /* max-width: 80vw !important; */
    white-space: normal !important;
    /* wrap this damn bookmarks! */
}

ul.volto-bookmarks-list li {
    list-style: none;
}

ul.volto-bookmarks-list .bookmarkgroup {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

ul.volto-bookmarks-list .bookmarkgroup ul {
    padding: 0;
}

ul.volto-bookmarks-list .bookmarkgroup li.bookmarkitem {
    display: flex;
    justify-content: space-between;
}

ul.volto-bookmarks-list li.bookmarkitem button {
    vertical-align: text-bottom;
}

.volto-bookmarks-info {
    padding: .7rem 1rem;
    white-space: normal !important;
    line-height: 1.3;
}

.ui.basic.button#toolbar-addbookmark,
.ui.basic.button.deletebookmark {
    box-shadow: none;
}